<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                    <b-row>
                       <b-col lg="6" sm="12">
                        <ValidationProvider name="Organization Name" vid="organization_name" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="organization_name"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('research_manage.organization_name')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="committee_setup.organization_name"
                                :options="orgList"
                                id="organization_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Thematic Area" vid="thematic_area_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="thematic_area_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('research_manage.thematic_area')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="committee_setup.thematic_area_id"
                                :options="thematicAreaList"
                                @change="getProposalCircular(committee_setup.thematic_area_id)"
                                id="thematic_area"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Proposal Invitation Circular" vid="proposal_invitation_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="proposal_invitation_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('research_manage.proposal_invitation_circular')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="committee_setup.proposal_invitation_id"
                                :options="proposalData"
                                id="organization_name"
                                @change="setResearchProgram(committee_setup.proposal_invitation_id)"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Committee Type" vid="committee_type" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="committee_type"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('research_manage.committee_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="committee_setup.committee_type"
                                :options="committeeData"
                                id="committee_type"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Research Program" vid="research_program" rules="">
                          <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="research_program"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.research_program')}}
                              </template>
                              <b-form-input
                              id="research_program"
                              v-model="committee_setup.research_program"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Comittee Name(En)" vid="committee_setup_name_en" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="committee_setup_name_en"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.committee_name_enn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="committee_setup_name_en"
                              v-model="committee_setup.committee_setup_name_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Committee Name(Bn)" vid="committee_setup_name_bn" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="committee_setup_name_bn"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.committee_name_bnn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="program_description_en"
                              v-model="committee_setup.committee_setup_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Committee Description (En)" vid="committee_setup_description_en" rules="">
                          <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="committee_setup_description_en"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.committee_description_enn')}}
                              </template>
                              <b-form-textarea
                              id="committee_description_en"
                              v-model="committee_setup.committee_setup_description_en"
                              :options="org"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Program Start Year" vid="committee_setup_description_bn" rules="">
                          <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="committee_setup_description_bn"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.committee_description_bnn')}}
                              </template>
                              <b-form-textarea
                              id="program_start_year"
                              v-model="committee_setup.committee_setup_description_bn"
                              :options="org"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="5"
                          :label="$t('research_manage.committee_setup_date')"
                          label-for="to_date"
                          >
                          <flat-pickr class="form-control"
                          v-model="committee_setup.committee_setup_date"
                          id="to_date"
                          >
                          </flat-pickr>
                      </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="bg-success text-white text-center mb-3">
                        <h4 class="text-center w-50 m-auto">{{$t('research_manage.role_info')}}</h4>
                    </b-row>
                    <b-row v-for="(detail,index) in committee_setup_extra.role" :key="index" class="text-black mb-3">
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Role Name(En)" vid="role_name_en" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="role_name_en"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.role_name_enn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="program_start_month"
                                        v-model="detail.role_name_en"
                                        :options="month"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Role Name(Bn)" vid="role_name_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="role_name_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.role_name_bnn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="role_name_bn"
                                        v-model="detail.role_name_bn"
                                        :options="org"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xl="6" lg="6" sm="6">
                                </b-col>
                                <b-col xl="6" lg="6" sm="6">
                                <button @click="remove(index)" class="btn btn-sm btn-danger float-right" type="button" v-show="index || ( !index && committee_setup_extra.role.length > 1)"><i class="fas fa-window-close m-0"></i> {{ $t('globalTrans.delete') }}</button>
                                </b-col>
                                <b-col xl="6" lg="6" sm="6">
                                </b-col>
                                <b-col xl="6" lg="6" sm="6">
                                  <button @click="addItem" class="btn btn-sm btn-primary mr-2 float-right mt-4" type="button" v-show="index == committee_setup_extra.role.length-1"><i class="fas fa-plus-circle m-0"></i> {{$t('globalTrans.add_more')}}</button>
                                </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../../config/api_config'
import { CommitteeSetupInfoStore, CommitteeSetupInfoUpdate, proposalInvitationListCustom } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id', 'proposalList', 'committeeData', 'roledata'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        org: [],
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        proposalData: [],
        committee_setup: {
          organization_name: '',
          proposal_invitation_id: '',
          research_program: '',
          committee_type: '',
          committee_setup_name_en: '',
          committee_setup_name_bn: '',
          committee_setup_description_en: '',
          committee_setup_description_bn: '',
          committee_setup_date: '',
          role: [
          {
          role_name_en: '',
          role_name_bn: ''
          }
        ]
        },
        committee_setup_extra: {
          role: [
          {
          role_name_en: '',
          role_name_bn: ''
          }
         ]
        }
    }
  },
  computed: {
    thematicAreaList: function () {
        return this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
    },
    sectorList: function () {
        return this.$store.state.AgriResearch.commonObj.sectorList
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  watch: {
  },
  created () {
        if (this.id) {
          try {
        const tmp = this.getSubSectorData()
        // this.committee_setup = tmp
        // this.getProposalCircular(this.committee_setup.thematic_area_id)
        this.getProposalCircular(tmp.thematic_area_id, tmp)
          } catch (e) {
            alert(e)
          }
        this.committee_setup.role = this.roledata.filter(element => element.id === this.id)
        const education = this.roledata.filter(item => parseInt(item.committee_setup_id) === this.id)
        if (education.length > 0) {
        this.committee_setup_extra.role = []
        this.committee_setup_extra.role.push(...education)
        } else {
        this.committee_setup_extra.role = []
        this.addItem()
        }
    }
    this.org = [
      { value: '2021', text: '2021' },
      { value: '2020', text: '2020' },
      { value: '2019', text: '2019' },
      { value: '2018', text: '2018' },
      { value: '2017', text: '2017' },
      { value: '2016', text: '2016' },
      { value: '2015', text: '2015' },
      { value: '2014', text: '2014' },
      { value: '2013', text: '2013' },
      { value: '2012', text: '2012' },
      { value: '2011', text: '2011' },
      { value: '2010', text: '2010' },
      { value: '2009', text: '2009' },
      { value: '2008', text: '2008' },
      { value: '2007', text: '2007' },
      { value: '2006', text: '2006' },
      { value: '2005', text: '2005' },
      { value: '2004', text: '2004' },
      { value: '2003', text: '2003' },
      { value: '2001', text: '2001' },
      { value: '2000', text: '2000' },
      { value: '1999', text: '1999' },
      { value: '1998', text: '1998' },
      { value: '1997', text: '1997' },
      { value: '1996', text: '1996' },
      { value: '1995', text: '1995' },
      { value: '1994', text: '1994' },
      { value: '1993', text: '1993' },
      { value: '1992', text: '1992' },
      { value: '1991', text: '1991' },
      { value: '1990', text: '1990' },
      { value: '1989', text: '1989' },
      { value: '1988', text: '1988' },
      { value: '1987', text: '1987' },
      { value: '1986', text: '1986' },
      { value: '1985', text: '1985' },
      { value: '1984', text: '1984' },
      { value: '1983', text: '1983' },
      { value: '1982', text: '1982' },
      { value: '1981', text: '1981' },
      { value: '1980', text: '1980' },
      { value: '1979', text: '1979' },
      { value: '1978', text: '1978' },
      { value: '1977', text: '1977' },
      { value: '1976', text: '1976' },
      { value: '1975', text: '1975' },
      { value: '1974', text: '1974' },
      { value: '1973', text: '1973' },
      { value: '1972', text: '1972' },
      { value: '1971', text: '1971' },
      { value: '1970', text: '1970' },
      { value: '1969', text: '1969' }
      ]
    this.month = [
      { value: 'january', text: 'January' },
      { value: 'february', text: 'February' },
      { value: 'march', text: 'March' },
      { value: 'april', text: 'April' },
      { value: 'may', text: 'May' },
      { value: 'june', text: 'June' },
      { value: 'july', text: 'July' },
      { value: 'august', text: 'August' },
      { value: 'september', text: 'September' },
      { value: 'october', text: 'October' },
      { value: 'novmber', text: 'Novmber' },
      { value: 'december', text: 'December' }
      ]
  },
  mounted () {
    core.index()
    if (this.id) {
    }
  },
  methods: {
    getProposalCircular (id, tmp = null) {
        const params = Object.assign({}, this.search, { id: id })
        RestApi.postData(agriResearchServiceBaseUrl, proposalInvitationListCustom, params).then(response => {
          if (response.success) {
            this.proposalData = response.data.map(el => {
              return Object.assign({}, el, { text: this.$i18n.locale === 'en' ? 'Circular Id-' + el.text_en : 'সার্কুলার আইডি-' + el.text_bn })
            })
            if (this.id) {
              this.committee_setup = tmp
            }
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    setResearchProgram (id) {
    const proposal = this.proposalList.find(element => element.id === id)
    this.committee_setup.research_program = proposal.research_objectives
    },
    remove (key) {
        this.committee_setup_extra.role.splice(key, 1)
    },
    addItem () {
      const obj = {
          role_name_en: '',
          role_name_bn: ''
      }
      // const key1 = parseInt(this.committee_setup.role.length - 1)
      // const item = this.formData.unit_list_details[key1]
      this.committee_setup_extra.role.push(obj)
    },
    getSubSectorData () {
      const tmpData = this.$store.state.list.find(project => project.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        this.committee_setup.role = []
        this.committee_setup.role = this.committee_setup_extra.role
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${CommitteeSetupInfoUpdate}/${this.id}`, this.committee_setup)
      } else {
        this.committee_setup.role = []
        this.committee_setup.role = this.committee_setup_extra.role
        result = await RestApi.postData(agriResearchServiceBaseUrl, CommitteeSetupInfoStore, this.committee_setup)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
